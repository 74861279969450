import { CommonProps } from "@vahak/core-ui/dist/types";
import React, { ReactNode } from "react";
import Loader from "@vahak/core/dist/components/loader";
import PlaceholderIcon from "@vahak/core/dist/icons/UploadPlaceHolder.svg";
import NoRecordFound from "../components/dashboard/list-wrapper/no-record-found/NoRecordFound";
import { CSSObject } from "styled-components";
interface LoaderPlaceholderSwitchProps extends CommonProps {
    placeholderSlot?: ReactNode;
    isLoading: boolean;
    isEmptyList: boolean;
    LoaderSlot?: React.ReactNode;
}

export const CommonPlaceholderView = ({
    text = "No Record found",
    height = 400,
    width = 400
}: {
    text?: string;
    height?: CSSObject["height"];
    width?: CSSObject["width"];
}) => {
    return (
        <div aria-describedby="placeholder" style={{ height, width }}>
            <NoRecordFound message={text} icon={<PlaceholderIcon />} />
        </div>
    );
};

const LoaderPlaceholderSwitch = ({
    children,
    isEmptyList,
    isLoading,
    placeholderSlot,
    LoaderSlot
}: LoaderPlaceholderSwitchProps) => {
    if (isLoading) {
        return (
            <>
                {LoaderSlot ?? (
                    <div
                        aria-describedby="loader"
                        style={{ position: "relative", minHeight: "400px", height: "100%", width: "100%" }}
                    >
                        <Loader isFullPage={false} />
                    </div>
                )}
            </>
        );
    }
    return <>{isEmptyList ? placeholderSlot : children}</>;
};

export default LoaderPlaceholderSwitch;
